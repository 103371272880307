/*
* Admin Layout (laqira)
* @author: Pixelwibes
* @design by: Pixelwibes.
* @event-namespace:laqira
* Copyright 2022 Pixelwibes
*/
.table-hover>tbody>tr:hover{
    --bs-table-accent-bg: var(--color-100);
}
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before, 
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before{
    top: 50%;
    background-color:var(--primary-color);
    transform: translateY(-50%);
}
table.dataTable>tbody>tr.child ul.dtr-details{
    display: block;
}

div.dataTables_wrapper{
    .dataTables_length{
        @media only screen and (max-width:  $break-small - 1px) {
            display: none;
        }
    }
    div.dataTables_filter{
        @media only screen and (max-width:  $break-small - 1px) {
            text-align: left !important;   
        }
    }
    div.dataTables_info{
        @media only screen and (max-width:  $break-small - 1px) {
            display: none !important; 
        }
    }
    div.dataTables_paginate{
        ul.pagination{
            @media only screen and (max-width:  $break-small - 1px) {
                justify-content: start!important;  
            }
        }
    } 
} 

#priceTableup_wrapper,#priceTabledown_wrapper,#priceTableuponly_wrapper,#priceTabledownonly_wrapper{
    .dataTables_filter,.dataTables_length,.dataTables_info,.dataTables_paginate{
        display: none;
    }
}